<template>
  <v-dialog max-width="450" persistent :value="true">
    <v-card>
      <v-card-title>
        최초 로그인 시, 관리자의 접근권한 설정이 필요합니다.
      </v-card-title>
      <v-card-actions class="action">
        <v-spacer></v-spacer>
        <v-btn
          color="#004483"
          style="font-weight: 600"
          small
          dark
          elevation="0"
          @click="logOut()"
          >돌아가기</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    logOut() {
      this.$emit("logOut");
    },
  },
};
</script>

<style scoped>
.action {
  padding-top: 10px;
}
</style>
